import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { orderPickupButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/TLwN1E9PILpp3r7vZzJW/locations/0PD38U248tTEtGgf1ZMw`}
            >
              <CFView hover>
                <CFImage
                  src={orderPickupButton}
                  w="90%"
                  maxWidth="300px"
                  alt="About"
                />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/TLwN1E9PILpp3r7vZzJW/locations/0PD38U248tTEtGgf1ZMw`}
            >
              <CFView hover w="90%">
                <CFImage src={orderPickupButton} maxWidth="250px" alt="About" />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
